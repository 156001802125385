import React, { useState } from "react";
import { Menu, useSidebarState } from "react-admin";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ProtectedRoute from "../../config/ProtectedRoute";

import {
    SettingsIcon, DashboardIcon, ManageDBIcon, ManageConfigurationIcon, ApisIcon,
    WorkManagementIcon, DatabaseOverviewIcon, CreateVariablesIcon, UpdateVariablesIcon,
    CreateSystemIcon, SystemIcon, InventoryIcon, LogoutIcon, NotificationIcon, Settings
} from "./Svg";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `0px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={props.expandable ? <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: '#f5f5f5' }} /> : null}
        {...props}
    />
))(({ theme }) => ({
    height: '48px',
    backgroundColor:
        theme.palette.mode === 'none'
            ? 'none'
            : 'none',
    flexDirection: 'row',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
    '&:focus': {
        backgroundImage: 'linear-gradient(to right, rgba(46, 50, 56, 0.2), rgba(46, 50, 56, 1))',
    },
    '&:hover': {
        backgroundImage: 'linear-gradient(to right, rgba(46, 50, 56, 0.2), rgba(46, 50, 56, 1))',
    },
    '&:active': {
        backgroundImage: 'linear-gradient(to right, rgba(46, 50, 56, 0.2), rgba(46, 50, 56, 1))',
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    width: '220px',
    height: '48px',
    borderRadius: '5px',
    marginLeft: '36px',
    padding: '6px 10px',
    '&:focus': {
        backgroundImage: 'linear-gradient(to right, rgba(46, 50, 56, 0.2), rgba(46, 50, 56, 1))',
    },
    '&:hover': {
        backgroundImage: 'linear-gradient(to right, rgba(46, 50, 56, 0.2), rgba(46, 50, 56, 1))',
    },
    '&:active': {
        backgroundImage: 'linear-gradient(to right, rgba(46, 50, 56, 0.2), rgba(46, 50, 56, 1))',
    },
    [theme.breakpoints.down('sm')]: {
        padding: '0px 10px',
    },
}));




export const MyMenu = () => {
    const [open, setOpen] = useSidebarState(true);
    const navigate = useNavigate();

    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const handleDashboard = () => {navigate("/firehouse/copy-database")}
    const handleCopyDatabase = () => {navigate("/firehouse/copy-database")}
    const handleNewCustomer = () => {navigate("/firehouse/new-customer")}
    const handleUpdateVariables = () => {navigate("/firehouse/update-variables")}

    return (
        <div >
            <Menu
                className="mennu"
                sx={{
                    margin: 0,
                    background: "radial-gradient(circle at 0%  ,#5362EBd1 -80%,#1E1E1E 40%)",
                    height: "100vh",
                    color: "#fff",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: open ? "space-between" : "center",
                        height: "110px",
                    }}
                >
                    <img className="ms-3 my-3" src="/images/LogoColor.svg" style={{ display: open ? 'block' : 'none' }} />



                    <Button
                        sx={{
                            color: "#FFF", height: "74px", width: "74px",
                            '&.MuiButton-root:active': { backgroundColor: "#2E3238" },
                            '&.MuiButton-root:hover': { backgroundColor: "transparent" }
                        }}
                        onClick={() => setOpen(!open)}
                        endIcon={
                            open ? (
                                <KeyboardDoubleArrowLeftIcon style={{ fontSize: 30 }} />
                            ) : (
                                <KeyboardDoubleArrowRightIcon style={{ fontSize: 30 }} />
                            )
                        }
                    />
                </Box>

                <div style={{ marginTop: '36px' }} className="accordion-work">
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} onClick={handleDashboard}>
                        <AccordionSummary sx={{ borderRadius: '4px', margin: '5px' }} className='accor-height' expandable={false}>
                            <ProtectedRoute>
                                <Menu.Item
                                    sx={{ 
                                        paddingLeft: open ? "0px" : "20px", 
                                        color: "white", 
                                        fontSize: '14px' ,
                                        "&.RaMenuItemLink-active":{
                                            color:'white',
                                        }
                                    }}
                                    to="/firehouse/dashboard"
                                    primaryText={open ? "Dashboard" : null}
                                    leftIcon={<DashboardIcon width="24px" height="24px" />}
                                />
                            </ProtectedRoute>
                        </AccordionSummary>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary sx={{ borderRadius: '4px', margin: '5px' }} className='accor-height' expandable={true}>
                            <ProtectedRoute>
                                <Menu.Item
                                    sx={{ paddingLeft: open ? "0px" : "34px", 
                                        color: "white", 
                                        fontSize: '14px',
                                        "&.RaMenuItemLink-active":{
                                            color:'white',
                                        }
                                    }}
                                    to="#"
                                    primaryText={open ? "Manage DB's" : null}
                                    leftIcon={<ManageDBIcon width="24px" height="24px" />}
                                />
                            </ProtectedRoute>
                        </AccordionSummary>
                        {open && (
                            <>  <div className="accordion-work">
                                <div>
                                    <AccordionDetails onClick={handleCopyDatabase}>
                                        <ProtectedRoute>
                                            <Menu.Item
                                                sx={{ 
                                                    paddingLeft: open ? "5px" : "8px", 
                                                    color: "white", 
                                                    fontSize: '14px',
                                                    "&.RaMenuItemLink-active":{
                                                        color:'white',
                                                    }
                                                 }}
                                                to="/firehouse/copy-database"
                                                primaryText="Copy Live DBS"
                                                leftIcon={<SettingsIcon width="24px" height="24px" />}
                                            />
                                        </ProtectedRoute>
                                    </AccordionDetails>
                                    <AccordionDetails onClick={handleNewCustomer}>
                                        <ProtectedRoute>
                                            <Menu.Item
                                                sx={{ paddingLeft: open ? "5px" : "8px", 
                                                    color: "white", 
                                                    fontSize: '14px',
                                                    "&.RaMenuItemLink-active":{
                                                        color:'white',
                                                    }
                                                 }}
                                                to="/firehouse/new-customer"
                                                primaryText="Create new customer"
                                                leftIcon={<WorkManagementIcon width="24px" height="24px" />}
                                            />
                                        </ProtectedRoute>
                                    </AccordionDetails>
                                    <AccordionDetails>
                                        <ProtectedRoute>
                                            <Menu.Item
                                                sx={{ paddingLeft: open ? "5px" : "8px", 
                                                    color: "white", 
                                                    fontSize: '14px',
                                                    "&.RaMenuItemLink-active":{
                                                        color:'white',
                                                    }
                                                }}
                                                to="/firehouse/database-overview"
                                                primaryText="Database Overview"
                                                leftIcon={<DatabaseOverviewIcon width="24px" height="24px" />}
                                            />
                                        </ProtectedRoute>
                                    </AccordionDetails>
                                </div>
                            </div>
                            </>
                        )}
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary sx={{ borderRadius: '4px', margin: '5px' }} className='accor-height' expandable={true}>
                            <ProtectedRoute>
                                <Menu.Item
                                    sx={{ paddingLeft: open ? "0px" : "34px", 
                                        color: "white", 
                                        fontSize: '14px',
                                        "&.RaMenuItemLink-active":{
                                            color:'white',
                                        }
                                     }}
                                    to="#"
                                    primaryText={open ? "Manage Configuration" : null}
                                    leftIcon={<ManageConfigurationIcon width="24px" height="24px" />}
                                />
                            </ProtectedRoute>
                        </AccordionSummary>
                        {open && (
                            <>
                                <AccordionDetails onClick={handleUpdateVariables}>
                                    <ProtectedRoute>
                                        <Menu.Item
                                            sx={{ paddingLeft: open ? "5px" : "8px", 
                                                color: "white", 
                                                fontSize: '14px',
                                                "&.RaMenuItemLink-active":{
                                                    color:'white',
                                                }
                                            }}
                                            to="/firehouse/update-variables"
                                            primaryText="Update Variables"
                                            leftIcon={<UpdateVariablesIcon width="24px" height="24px" />}
                                        />
                                    </ProtectedRoute>
                                </AccordionDetails>
                                <AccordionDetails>
                                    <ProtectedRoute>
                                        <Menu.Item
                                            sx={{ paddingLeft: open ? "5px" : "8px", 
                                                color: "white", 
                                                fontSize: '14px',
                                                "&.RaMenuItemLink-active":{
                                                    color:'white',
                                                }
                                            }}
                                            to="/firehouse/createVariables"
                                            primaryText="Create Variables"
                                            leftIcon={<CreateVariablesIcon width="24px" height="24px" />}
                                        />
                                    </ProtectedRoute>
                                </AccordionDetails>
                                <AccordionDetails>
                                    <ProtectedRoute>
                                        <Menu.Item
                                            sx={{ paddingLeft: open ? "5px" : "8px", 
                                                color: "white", 
                                                fontSize: '14px',
                                                "&.RaMenuItemLink-active":{
                                                    color:'white',
                                                } 
                                            }}
                                            to="/firehouse/createSystem"
                                            primaryText="Create System Options"
                                            leftIcon={<CreateSystemIcon width="24px" height="24px" />}
                                        />
                                    </ProtectedRoute>
                                </AccordionDetails>
                            </>
                        )}
                    </Accordion>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary sx={{ borderRadius: '4px', margin: '5px' }} className='accor-height' expandable={true}>
                            <ProtectedRoute>
                                <Menu.Item
                                    sx={{ paddingLeft: open ? "0px" : "34px", 
                                        color: "white", 
                                        fontSize: '14px',
                                        "&.RaMenuItemLink-active":{
                                            color:'white',
                                        }
                                    }}
                                    to="#"
                                    primaryText={open ? "Third Party Apis" : null}
                                    leftIcon={<ApisIcon width="0px" height="24px" />}
                                />
                            </ProtectedRoute>
                        </AccordionSummary>
                        {open && (
                            <>
                                <AccordionDetails>
                                    <ProtectedRoute>
                                        <Menu.Item
                                            sx={{ paddingLeft: open ? "5px" : "8px", 
                                                color: "white", 
                                                fontSize: '14px',
                                                "&.RaMenuItemLink-active":{
                                                    color:'white',
                                                }
                                            }}
                                            to="/firehouse/epr"
                                            primaryText="EPR"
                                            leftIcon={<SystemIcon width="24px" height="24px" />}
                                        />
                                    </ProtectedRoute>
                                </AccordionDetails>
                                <AccordionDetails>
                                    <ProtectedRoute>
                                        <Menu.Item
                                            sx={{ paddingLeft: open ? "5px" : "8px", 
                                                color: "white", 
                                                fontSize: '14px',
                                                "&.RaMenuItemLink-active":{
                                                    color:'white',
                                                }
                                            }}
                                            to="/firehouse/proPhenox"
                                            primaryText="Pro Phenox"
                                            leftIcon={<SystemIcon width="24px" height="24px" />}
                                        />
                                    </ProtectedRoute>
                                </AccordionDetails>
                                <AccordionDetails>
                                    <ProtectedRoute>
                                        <Menu.Item
                                            sx={{ paddingLeft: open ? "5px" : "8px", 
                                                color: "white", 
                                                fontSize: '14px',
                                                "&.RaMenuItemLink-active":{
                                                    color:'white',
                                                }
                                            }}
                                            to="/firehoue/googleAnalytics"
                                            primaryText="Google Analytics"
                                            leftIcon={<InventoryIcon width="24px" height="24px" />}
                                        />
                                    </ProtectedRoute>
                                </AccordionDetails>
                            </>
                        )}
                    </Accordion>
                </div>
            </Menu>
        </div>
    );
};
