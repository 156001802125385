import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import { IconButton, InputAdornment, FormControl } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function UseFormControl({onSearch}) {
  const handleSearchChange = (event) => {
    onSearch(event.target.value);
  };

  const theme = useTheme();
  return (
    <div noValidate autoComplete="off">
      <FormControl>
        <OutlinedInput 
            sx={{
              backgroundColor: '#fff',
              height:'52px'
            }} 
            placeholder="Search here"
            onChange={handleSearchChange}
            endAdornment={
            <InputAdornment position="end">
                <IconButton edge="end">
                <SearchIcon />
                </IconButton>
            </InputAdornment>
            }
        />
        </FormControl>
    </div>
  );
}
