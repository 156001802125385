import React from 'react';
import { Box, Modal, Button, TextField } from '@mui/material';

const UpdateModal = ({ open, onClose, modalData, onUpdate, onUpdateData, selectedDepartment }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: '#f5f5f5',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <TextField
          label="Department"
          value={modalData.department}
          fullWidth
          margin="normal"
          InputProps={{ readOnly: true }}
          sx={{
            backgroundColor:'#fff',
            height: '48px', 
            '& .MuiInputBase-root': {
            height: '48px',
            },
          }}
        />
        <TextField
          label="Variable Name"
          value={modalData.variableName}
          fullWidth
          margin="normal"
          InputProps={{ readOnly: true }}
          sx={{
            backgroundColor:'#fff',
            height: '48px', 
            '& .MuiInputBase-root': {
            height: '48px',
            },
          }}
        />
        <TextField
          label="Current Value"
          value={modalData.currentValue}
          fullWidth
          margin="normal"
          InputProps={{ readOnly: true }}
          sx={{
            backgroundColor:'#fff',
            height: '48px', 
            '& .MuiInputBase-root': {
            height: '48px',
            },
          }}
        />
        <TextField
          label="New Value"
          value={modalData.newValue}
          fullWidth
          margin="normal"
          size='small'
          sx={{
            backgroundColor:'#fff',
            height: '48px', 
            '& .MuiInputBase-root': {
            height: '48px',
            },
          }}
          onChange={(e) =>
            onUpdate((prev) => ({ ...prev, newValue: e.target.value }))
          }
        />
        <Box sx={{ display: 'flex', mt: 1 , justifyContent:'space-evenly'}}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            color="error"
            className='rounded-5'
            disabled={!modalData.newValue}
            sx={{backgroundColor:'#BF1D2D'}}
            onClick={() => {
              onUpdateData(); 
              onClose();
            }}
          >
            Update
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UpdateModal;
