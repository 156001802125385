import React, { useEffect, useState } from "react";
import HeaderRoutes from "../../components/HeaderRoutes/HeaderRoutes";
import { Grid , Box , Paper, Typography , Button} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTheme,styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import OutlinedInput from '@mui/material/OutlinedInput';
import axios from 'axios';
import config from '../../config/config';
import Toast from '../../config/Toast';
import { DataGrid } from '@mui/x-data-grid';

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  fontFamily:'karla',
  color:'#000000',
  fontSize: '20px',
  fontWeight: '500',
  '&.Mui-focused': {
      color:'#000000',
    },
}));

const NewCustomer = () => {
    const [dataName, setDataName] = useState([]); 
    const [dbName, setDbName] = useState('');
    const [typee , setTypee] = useState('');
    const [selectedName, setSelectedName] = useState('');
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [databaseList, setDatabaseList] = useState([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [type, setType] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [searchQuery, setSearchQuery] = useState('');

    const [paginationModel, setPaginationModel] = React.useState({
      pageSize: 10,
      page: 0,
    });

    const columns = [
      { 
        field: 'schema_name', 
        headerName: 'Schema Name', 
        flex: 1,  
        minWidth: 200, 
        renderCell: (params) => (
          <div style={{ wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}>
            {params.value}
          </div>
        ),
      },
      { 
        field: 'db_user', 
        headerName: 'User ID', 
        valueGetter: (params) => params.row.db_user || 'N/A',
        flex: 1,
        minWidth: 150,  
        renderCell: (params) => (
          <div style={{ wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}>
            {params.value}
          </div>
        ),
      },
      { 
        field: 'type', 
        headerName: 'Type', 
        flex: 1,
        minWidth: 80,
        renderCell: (params) => (
          <div style={{ wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}>
            {params.value}
          </div>
        ),
      },
      { 
        field: 'db_copy_from', 
        headerName: 'Copied From', 
        valueGetter: (params) => params.row.db_copy_from || 'N/A',
        flex: 1,
        minWidth: 240,  
        renderCell: (params) => (
          <div style={{ wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}>
            {params.value}
          </div>
        ),
      },
      { 
        field: 'created_at', 
        headerName: 'Created At', 
        valueGetter: (params) => new Date(params.row.created_at).toLocaleString(),
        flex: 1,
        minWidth: 200,  
        renderCell: (params) => (
          <div style={{ wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}>
            {params.value}
          </div>
        ),
      },
    ];

    const handleCreate = async () => {
      const errors = {};
      if (!dbName) errors.dbName = "Database name is required.";
      if (!selectedName) errors.selectedName = "Please select a database to copy from.";
      if (!typee) errors.typee = "Database type is required.";
      if (!userId) errors.userId = "User ID is required.";
      if (!password) errors.password = "Password is required.";
    
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
      }
    
      const data = {
        schema_name: dbName,
        type: typee,
        db_copy_from: selectedName,
        db_user: userId,
        db_password: password,
      };
    
      const path = `${config.authServiceUrl}database/create`;
      const token = localStorage.getItem("tokenId");
    
      try {
        const response = await axios.post(path, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setMessage('New Database Created Successfully');
        setType('success');
        setSnackbarOpen(true);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } catch (error) {
        const apiError = error.response?.data?.message || "An error occurred.";
        setMessage(apiError);
        setType('error');
        setSnackbarOpen(true);
      }
    };
    
    const dataList = async () => {
      const data = {
        type: '',
      };
      const path = `${config.authServiceUrl}database/created-list`;
      const token = localStorage.getItem("tokenId");
      try {
        const response = await axios.post(path, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setDatabaseList(response.data.data);
        console.log("data", response.data.data);
      } catch (error) {
        console.error(error.response?.data);
      }
    };
    const fetchDropdownData = async () => {
      const requestData = {
        type: 'LIVE',
      };
  
      const path = `${config.authServiceUrl}database/list`;
      const token = localStorage.getItem('tokenId');
  
      try {
        const response = await axios.post(path, requestData, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.data && Array.isArray(response.data.data)) {
          setDataName(response.data.data.map((item) => item.tenancy_db_name));
        } else {
          console.error('Unexpected response format:', response.data);
        }
      } catch (error) {
        console.error('Error fetching dropdown data:', error.response?.data?.message || error.message);
      }
    };
    useEffect(() => {
      dataList();
      fetchDropdownData();
    }, []);
    const handleCloseSnackbar = () => {
      setSnackbarOpen(false);
    };

     
  return (
    <>
      <HeaderRoutes />
        <Paper sx={{ height: 'fit-content', margin: isMobile ? '16px' : '35px', padding: '28px' }}>
          <Typography
            style={{
              fontFamily: 'Karla',
              fontStyle: 'normal',
              fontSize: '20px',
              lineHeight: '150%',
              color: '#000000',
              fontWeight: 500,
            }}
          >
            Create New Customer Databases
          </Typography>

          <Box sx={{ mt: 4 }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <FormControl variant="standard" sx={{ width: '100%' }}>
                  <StyledInputLabel shrink htmlFor="bootstrap-input">New Databases</StyledInputLabel>
                    <OutlinedInput
                      sx={{ height: '48px', marginTop: '30px' }}
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{ 'aria-label': 'weight' }}
                      placeholder="New databases"
                      value={dbName}
                      onChange={(e) => {
                        setDbName(e.target.value);
                        setFormErrors({ ...formErrors, dbName: null });
                      }}
                    />
                    {formErrors.dbName && (
                      <Typography color="error" variant="caption">
                        {formErrors.dbName}
                      </Typography>
                    )}
              </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <FormControl variant="standard" sx={{ width: '100%' }}>
                    <StyledInputLabel shrink htmlFor="bootstrap-input">Copy from current database</StyledInputLabel>
                  </FormControl>
                  <FormControl
                    className="form-controll"
                    style={{ marginTop: '6px', width: '100%' }}
                    error={!!formErrors.selectedName}
                  >
                    <Select
                      displayEmpty
                      value={selectedName}
                      onChange={(e) => {
                        setSelectedName(e.target.value);
                        setFormErrors({ ...formErrors, selectedName: null });
                      }}
                      renderValue={(selected) => {
                        if (!selected) {
                          return (
                            <em>
                              <img
                                src="/images/search-fade.svg"
                                alt="Database Icon"
                                style={{ marginRight: '10px', verticalAlign: 'middle' }}
                              />
                              Copy from current database
                            </em>
                          );
                        }
                        return selected;
                      }}
                      inputProps={{ 'aria-label': 'Without label' }}
                      sx={{ height: '48px' }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300, 
                            width: 250,
                          },
                        },
                        disableAutoFocusItem: true, 
                      }}
                    >
                      <MenuItem disableRipple style={{ padding: '8px' }}>
                        <OutlinedInput
                          placeholder="Search databases..."
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          fullWidth
                          onClick={(e) => e.stopPropagation()} 
                          onKeyDown={(e) => e.stopPropagation()}
                          sx={{ height: '36px' }}
                        />
                      </MenuItem>

                      <MenuItem divider style={{ pointerEvents: 'none', padding: 0 }} />

                      {dataName
                        .filter((name) => name.toLowerCase().includes(searchQuery.toLowerCase()))
                        .map((name, index) => (
                          <MenuItem key={index} value={name}>
                            <Checkbox checked={selectedName === name} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                    </Select>

                    {formErrors.selectedName && (
                      <Typography color="error" variant="caption" sx={{ marginTop: '8px' }}>
                        {formErrors.selectedName}
                      </Typography>
                    )}
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <Typography
                    style={{
                      fontFamily: 'Karla',
                      fontStyle: 'normal',
                      fontSize: '14px',
                      lineHeight: '150%',
                      color: '#000000',
                      fontWeight: 500,
                    }}
                  >
                    Database type
                  </Typography>
                </Box>
                <FormControl>
                  <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                    <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'left', marginTop: '20px', marginLeft: '20px' }}>
                      <FormControlLabel
                        control={
                          <img
                            src={typee === 'LIVE' ? '/images/after-checkbox.svg' : '/images/before-checkbox.svg'}
                            alt="Live"
                            width="27px"
                            height="27px"
                            onClick={() => {
                              setTypee('LIVE');
                              setFormErrors({ ...formErrors, typee: null }); 
                            }}
                            style={{ cursor: 'pointer', marginRight: '10px' }}
                          />
                        }
                        label="Live"
                        style={{ marginRight: '40px' }}
                      />
                      <FormControlLabel
                        control={
                          <img
                            src={typee === 'TEST' ? '/images/after-checkbox.svg' : '/images/before-checkbox.svg'}
                            alt="Test"
                            width="27px"
                            height="27px"
                            onClick={() => {
                              setTypee('TEST');
                              setFormErrors({ ...formErrors, typee: null }); 
                            }}
                            style={{ cursor: 'pointer', marginRight: '10px' }}
                          />
                        }
                        label="Test"
                      />
                    </Box>
                  </RadioGroup>
                  {formErrors.typee && (
                    <Typography color="error" variant="caption" sx={{ marginTop: '8px' }}>
                      {formErrors.typee}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl variant="standard" sx={{ width: '100%' }}>
                  <StyledInputLabel shrink htmlFor="bootstrap-input">DB User</StyledInputLabel>
                    <OutlinedInput
                      sx={{ height: '48px', marginTop: '30px' }}
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{ 'aria-label': 'weight' }}
                      placeholder="DB User"
                      value={userId}
                      onChange={(e) => {
                        setUserId(e.target.value);
                        setFormErrors({ ...formErrors, userId: null });
                      }}   
                    />
                    {formErrors.userId && (
                      <Typography color="error" variant="caption">
                        {formErrors.userId}
                      </Typography>
                    )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl variant="standard" sx={{ width: '100%' }}>
                  <StyledInputLabel shrink htmlFor="bootstrap-input">DB Password</StyledInputLabel>
                    <OutlinedInput
                      sx={{ height: '48px', marginTop: '30px' }}
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{ 'aria-label': 'password' }}
                      placeholder="DB Password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setFormErrors({ ...formErrors, password: null }); 
                      }}
                    />
                    {formErrors.password && (
                      <Typography color="error" variant="caption" sx={{ marginTop: '8px' }}>
                        {formErrors.password}
                      </Typography>
                    )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid container>
                <Box className='mt-3'>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCreate}
                      sx={{backgroundColor: '#BF1D2D', fontSize: '18px', fontWeight: '500', width: '220px', height: '48px' }}
                    >
                      Create Database
                    </Button>
                </Box>
            </Grid>
          </Box>
        </Paper>

        <Paper sx={{ margin: isMobile ? '16px' : '35px', padding: '20px 28px'}}>
          <Box sx={{maxWidth:'68vw'}}>
            <Typography
              style={{
                fontFamily: 'Karla',
                fontStyle: 'normal',
                fontSize: '20px',
                lineHeight: '150%',
                color: '#000000',
                fontWeight: 500,
              }}
            >
              List of Databases Created
            </Typography>
            <Box sx={{ mt: 1}}>
              {databaseList.length === 0 ? (
                <Typography>No databases found.</Typography>
              ) : (
                <DataGrid
                  rows={databaseList.map((database, index) => ({ id: index, ...database }))}
                  columns={columns}
                  pagination
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  rowsPerPageOptions={[5, 10, 20]}
                  disableSelectionOnClick
                />
              )}
            </Box>
          </Box>
        </Paper>
        <Toast
          snackbarOpen={snackbarOpen}
          handleCloseSnackbar={handleCloseSnackbar}
          message={message}
          type={type}
        />
      </>
  );
};

export default NewCustomer;



